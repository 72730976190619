<template>
    <div>
        <Html>
            <Head>
                <Title>WeTix - The all-in-one event ticketing platform.</Title>
            </Head>
        </Html>
        <Body></Body>
        <slot />
    </div>
</template>

<script>

export default {
    components: {

    },
    setup() {
        return {

        }
    },
}
</script>